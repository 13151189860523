import { env } from './env'

export const BizCourseGateway = {
  'aom-ai-qa': 'https://x-course.qa.edalearning.ai',
  'aom-ai-beta': 'https://x-course.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://x-course.prod.edalearning.ai'
}

export const BizCourseAPI = {
  'aom-ai-qa': 'https://x-course-api.qa.edalearning.ai',
  'aom-ai-beta': 'https://x-course-api.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://x-course-api.prod.edalearning.ai'
}

export const FeedBookApi = {
  'aom-ai-beta': 'https://audit-gateway.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://audit-gateway.prod.edalearning.ai'
}

export const AuditApi = {
  'aom-ai-qa': 'https://audit-gateway.qa.edalearning.ai',
  'aom-ai-beta': 'https://audit-gateway.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://audit-gateway.prod.edalearning.ai'
}

export const bizCourseGatewayHost = BizCourseGateway[env]
export const bizCourseAPIHost = BizCourseAPI[env]
export const feedBookHost = FeedBookApi[env]
export const auditApiHost = AuditApi[env]
