import { env } from './env'

export const UCConfig = {
  'aom-ai-qa': {
    host: 'uc-component.qa.edalearning.ai',
       origins: {
      UC: 'https://uc-gateway.qa.edalearning.ai',
      SSO: 'https://uc-sso.qa.edalearning.ai'
    },

  },
  'aom-ai-beta': {
    host: 'uc-component.th2-staging.apse1.ndpg.xyz',
       origins: {
      UC: 'https://uc-gateway.th2-staging.apse1.ndpg.xyz',
      SSO: 'https://uc-sso.th2-staging.apse1.ndpg.xyz'
    },

  },
  'aom-ai': {
    host: 'uc-component.prod.edalearning.ai',
       origins: {
      UC: 'https://uc-gateway.prod.edalearning.ai',
      SSO: 'https://uc-sso.prod.edalearning.ai'
    },

  }
}

export const ucConfig = UCConfig[env] || {}
